<template>
  <ul class="menu-nav">

    <li class="menu-section">
      <h4 class="menu-text">Bahan Posyandu</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      :to="'/material-stock/list'"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-friends"></i>
          <span class="menu-text">Persediaan Bahan</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/stock-history"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Riwayat Stok</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
export default {
  data(){
    return{
      user: false,
      classgroup: false,
      competenceStandard: false,
      academicCalendar: false,
      announcement: false,
      minimumCompletenessCriteria: false,
      schoolYear: false,
      major: false,
      grade: false,
    }
  },
  methods: {
    hasActiveChildren(match) {
      //("match", match)
      return this.$route["path"].indexOf(match) !== -1;
    },
    handleToggle(){
      this.$root.$emit('closeToggleAside')
      //("jalan di sini ============================================================================")
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8001" || access_right[a] == "8002") {
          this.user = true
        }
        if (access_right[a] == "8003" || access_right[a] == "8004") {
          this.classgroup = true
        }
        if (access_right[a] == "8005" || access_right[a] == "8006") {
          this.competenceStandard = true
        }
        if (access_right[a] == "8007" || access_right[a] == "8008") {
          this.academicCalendar = true
        }
        if (access_right[a] == "8009" || access_right[a] == "8010") {
          this.announcement = true
        }
        if (access_right[a] == "8011" || access_right[a] == "8012") {
          this.minimumCompletenessCriteria = true
        }
        if (access_right[a] == "8013" || access_right[a] == "8014") {
          this.schoolYear = true
        }
        if (access_right[a] == "8015" || access_right[a] == "8016") {
          this.major = true
        }
        if (access_right[a] == "8017" || access_right[a] == "8018") {
          this.grade = true
        }
      }
    },
  },
  mounted(){
    this.checkAccess()
  }
};
</script>
