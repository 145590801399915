<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
      style="background-color: #00b0ff"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-0 scroll scroll-pull ps"
        style="height: 528px;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <router-link to="/dashboard" v-if="dashboard">
            <li
            class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Beranda'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg active"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="8"
              >
                <span class="svg-icon svg-icon-xl svg-icon-primary svg-icon-white">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg src="/media/svg/icons/Home/Home.svg" /> -->
                  <i class="fas fa-home"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="/weighing-notes" v-if="activity">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Kegiatan'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="8"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-balance-scale"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!-- <div class="dropdown-divider"></div> -->
          <!--begin::Item-->
          <router-link to="/toddlers" v-if="register">
          <li
            class="nav-item mb-1"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Registrasi'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="6"
            >
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="mdi mdi-mother-nurse text-primary" style="font-size: 30px"></i>
              </span>
            </a>
          </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="/material-stock" v-if="material">
          <li
            class="nav-item mb-1"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Bahan & Material'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="4"
            >
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="fas fa-shopping-basket text-primary"></i>
                <!-- <inline-svg src="/media/svg/icons/Communication/Group.svg" /> -->
              </span>
            </a>
          </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="/staffs" v-if="staff">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Petugas'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="5"
              >
                <span class="svg-icon svg-icon-xl svg-icon-white">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg src="/media/svg/icons/Communication/Group.svg" /> -->
                  <i class="fas fa-users text-primary"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>     
          <!--begin::Item-->
          <!--begin::Item-->
          <router-link to="/masters/posyandu" v-if="master">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Data pendukung'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="3"
              >
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-table text-primary"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>     
          <!--begin::Item-->
          <!--begin::Item-->
          <router-link to="/masters/roles" v-if="setting">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Pengaturan'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="7"
              >
                <span class="svg-icon svg-icon-xl svg-icon-white">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg src="/media/svg/icons/Code/Settings4.svg" /> -->
                  <i class="fas fa-cog text-primary"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>     
          <!--begin::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 pb-lg-3"
      >
        <!--begin::Aside Toggle-->
        <span
          class="aside-toggle btn btn-icon btn-danger btn-hover-danger shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
          @click="handleAsideToggle"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!-- <KTQuickActions></KTQuickActions> -->
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!-- <KTQuickPanel></KTQuickPanel> -->
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div class="aside-secondary d-flex flex-row-fluid" style="background-position: right 45px bottom;">
      <!--begin::Workspace-->
      <div
        class="aside-workspace my-2"
        style="height: 824px; overflow: hidden; background-image: url('/img/default/aside-transparent.png'); background-repeat: no-repeat; background-position: bottom; background-size: 20rem;"
      >
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTMenu></KTMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab  class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <form class="p-2 p-lg-3">
              <div class="d-flex">
                <div class="input-icon h-40px">
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid h-40px"
                    placeholder="Search..."
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
                <div class="dropdown" v-b-tooltip.hover.right="'Quick actions'">
                  <a
                    href="#"
                    class="btn btn-icon btn-default btn-hover-primary ml-2 h-40px w-40px flex-shrink-0"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/Code/Compiling.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-md dropdown-menu-right"
                    style=""
                  >
                    <!--begin::Navigation-->
                    <ul class="navi navi-hover py-5">
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-drop"></i>
                          </span>
                          <span class="navi-text">New Group</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-list-3"></i>
                          </span>
                          <span class="navi-text">Contacts</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-rocket-1"></i>
                          </span>
                          <span class="navi-text">Groups</span>
                          <span class="navi-link-badge">
                            <span
                              class="label label-light-primary label-inline font-weight-bold"
                            >
                              new
                            </span>
                          </span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-bell-2"></i>
                          </span>
                          <span class="navi-text">Calls</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-gear"></i>
                          </span>
                          <span class="navi-text">Settings</span>
                        </a>
                      </li>
                      <li class="navi-separator my-3"></li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-magnifier-tool"></i>
                          </span>
                          <span class="navi-text">Help</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-bell-2"></i>
                          </span>
                          <span class="navi-text">Privacy</span>
                          <span class="navi-link-badge">
                            <span
                              class="label label-light-danger label-rounded font-weight-bold"
                              >5</span
                            >
                          </span>
                        </a>
                      </li>
                    </ul>
                    <!--end::Navigation-->
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">Projects</h3>
            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Bravio Application
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By James
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/010-vimeo.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Quick Reports
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By Ana
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/014-kickstarter.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      CRM Reporting Tool
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By Adam
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/009-hot-air-balloon.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      DB Management
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By CRA Team
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/008-infography.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Cloud Service
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By iC Team
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/007-disqus.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Disqus Project
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By PV Inc.
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0"
                      >Plurk Meeting</span
                    >
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                      >By Plurk Team.</a
                    >
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
            </div>
            <!--end::List-->
          </b-tab>
          <!--end::Tab Pane-->
          
          <!--begin::Tab Pane-->
          <b-tab  class="p-3 px-lg-7 py-lg-5">
          <!--end::Tab Pane-->
          <ClassMenu />
          <!--begin::Tab Pane-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <!-- <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                > -->
                  <Master />
                <!-- </perfect-scrollbar> -->
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <!-- <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                > -->
                  <MaterialAside />
                <!-- </perfect-scrollbar> -->
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <!-- <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                > -->
                  <StaffAside />
                <!-- </perfect-scrollbar> -->
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <!-- <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                > -->
                  <RegisterAside />
                <!-- </perfect-scrollbar> -->
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-500px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <!-- <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                > -->
                  <SettingAside />
                <!-- </perfect-scrollbar> -->
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu bg-transparent"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <!-- <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                > -->
                  <ActivityAside />
                <!-- </perfect-scrollbar> -->
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<style scoped>
/* .router-link-active{
  background-color: red;
} */

/* default color */
.btn.btn-clean span i {
    color: white !important;
    background-color: transparent;
    border-color: transparent;
}

/* btn hover */
.btn.btn-clean:hover {
    color: yellow !important;
    background-color: white !important;
    border-color: transparent;
}

.btn.btn-clean:hover span i {
    color: #00b0ff !important;
}

.btn.btn-clean:hover span inline-svg {
    color: #00b0ff !important;
}
/*  */

/* btn active */
.btn.btn-clean:not(:disabled):not(.disabled).active{
    background-color: green !important;
    border-color: transparent;
}
.btn.btn-clean:not(:disabled):not(.disabled).active span i{
    color: #00b0ff !important;
}
/*  */

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-clean:not(:disabled):not(.disabled).active, .show > .btn.btn-clean.dropdown-toggle, .show .btn.btn-clean.btn-dropdown {
    color: white !important;
    background-color: white !important;
    border-color: transparent;
}


</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";

import ClassMenu from "@/view/layout/aside/ClassroomAside";
import Master from "@/view/layout/aside/Master";
import DatabaseTeacherStudent from "@/view/layout/aside/DatabaseTeacherStudent";
import StaffAside from "@/view/layout/aside/StaffAside";
import RegisterAside from "@/view/layout/aside/RegisterAside";
import ActivityAside from "@/view/layout/aside/ActivityAside";
import MaterialAside from "@/view/layout/aside/MaterialAside";
import SettingAside from "@/view/layout/aside/SettingAside";

import ApiService from '@/core/services/api.service.js'
import TreeselectVue from '../../pages/plugins/Treeselect.vue';
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "KTAside",
  components: {
    KTBrand,
    KTMenu,
    KTQuickActions,
    KTQuickUser,
    KTQuickPanel,
    ClassMenu,
    Master,
    DatabaseTeacherStudent,
    StaffAside,
    RegisterAside,
    ActivityAside,
    MaterialAside,
    SettingAside
  },
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      base_url: process.env.BASE_URL,
      currentUser: getUser(),
      friends: [],
      // access
      dashboard: false,
      activity: false,
      register: false,
      material: false,
      staff: false,
      master: false,
      setting: false,
    };
  },
  methods: {
    handleAsideToggle(){
      this.$nextTick(() => {

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
    },
    handleChatAside(){
      this.$root.$emit("refreshListChatUser")
      this.$root.$emit("openChat", this.friends[0])
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
      
    },
    getAccessRight() {
      ApiService.get("api/users/me").then((data) => {
        // get access user
        ApiService.get("api/users/" + data.data.data.id).then((response) => {

            // this.setActiveMenu(response.data.meta.access_right_display);
            // window.localStorage.setItem(
            //   "access_right_display",
            //   JSON.stringify(response.data.meta.access_right_display)
            // );

            // get access role
            ApiService.get("api/roles/" + data.data.data.role_id).then(
              (result) => {

                let access_right = response.data.meta.access_right_display;
                let position_right = result.data.meta.access_right_display;

                let merge = [...access_right, ...position_right];

                window.localStorage.setItem(
                  "access_right_display",
                  JSON.stringify(merge)
                );

                this.setActiveMenu(result.data.meta.access_right_display);
                this.$root.$emit('refreshClassroomAside')
                this.$root.$emit('refreshMasterAside')
              }
            ).catch((error) => {
              this.setActiveMenu(response.data.meta.access_right_display);
              window.localStorage.setItem("access_right_display", JSON.stringify(response.data.meta.access_right_display));
              this.$root.$emit('refreshClassroomAside')
              this.$root.$emit('refreshMasterAside')
            })
        });
      });
    },
    async setActiveMenu(accessParams = ""){
      let access_right;
      if (accessParams == "") {
        let access_right_user = window.localStorage.getItem("access_right_display");
        access_right = JSON.parse(access_right_user);
      } else if (accessParams != "") {
        access_right = accessParams;
      }

      for(let a = 0; a < access_right.length; a++){
        // general
        if (access_right[a] == "0001") {
          this.dashboard = true;
        }

        //activity
        for(let activity_code = 1000; activity_code <= 1005; activity_code++){
          if (access_right[a] == activity_code) {
            this.activity = true;
          }
        }

        // register
        for(let register_code = 2000; register_code <= 2006; register_code++){
          if (access_right[a] == register_code) {
            this.register = true;
          }
        }

        // material
        for(let material_code = 3000; material_code <= 3004; material_code++){
          if (access_right[a] == material_code) {
            this.material = true;
          }
        }

        // staff
        for(let staff_code = 4000; staff_code <= 4004; staff_code++){
          if (access_right[a] == staff_code) {
            this.staff = true;
          }
        }

        // master
        for(let master_code = 5000; master_code <= 5007; master_code++){
          if (access_right[a] == master_code) {
            this.master = true;
          }
        }

        // setting
        for(let setting_code = 6000; setting_code <= 6006; setting_code++){
          if (access_right[a] == setting_code) {
            this.setting = true;
          }
        }

      }
    }
  },
  mounted() {

    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });

    this.$root.$on("closeToggleAside", () => {
      this.$nextTick(() => {

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
    });

    this.$root.$on("openActivityUser", () => {
      this.tabIndex = 5
    });

    this.$root.$on("openChatAside", () => {
      this.tabIndex = 6
    });

    this.setActiveMenu();
    this.$root.$on("setMenuActive", () => {
      this.getAccessRight();
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
