var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"menu-nav"},[(_vm.masterTitle)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-dark font-weight-bolder"},[_vm._v("Data Pendukung")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.posyandu)?_c('router-link',{attrs:{"to":"/masters/posyandu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-clinic-medical"}),_c('span',{staticClass:"menu-text text-dark"},[_vm._v("Daftar Posyandu")])])])]}}],null,false,3252801529)}):_vm._e(),(_vm.vitamin_types)?_c('router-link',{attrs:{"to":"/masters/vitamin-types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-pills"}),_c('span',{staticClass:"menu-text text-dark"},[_vm._v("Jenis Vitamin")])])])]}}],null,false,1263161955)}):_vm._e(),(_vm.immunization_types)?_c('router-link',{attrs:{"to":"/masters/immunization-types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-syringe"}),_c('span',{staticClass:"menu-text text-dark"},[_vm._v("Jenis Imunisasi")])])])]}}],null,false,1457938890)}):_vm._e(),(_vm.blood_types)?_c('router-link',{attrs:{"to":"/masters/blood-types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-tint"}),_c('span',{staticClass:"menu-text text-dark"},[_vm._v("Golongan Darah")])])])]}}],null,false,961528838)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }