var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"menu-nav"},[_vm._m(0),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Jabatan")])])])]}}],null,false,2520896963)}):_vm._e(),(_vm.admin)?_c('router-link',{attrs:{"to":"/masters/admin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Admin")])])])]}}],null,false,3602986719)}):_vm._e(),(_vm.user)?_c('router-link',{attrs:{"to":"/masters/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("User")])])])]}}],null,false,4052025985)}):_vm._e(),(_vm.roleAccess)?_c('router-link',{attrs:{"to":"/masters/user-managements/role-access"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hak Akses Peran")])])])]}}],null,false,3000444428)}):_vm._e(),(_vm.userAccess)?_c('router-link',{attrs:{"to":"/masters/user-managements/user-access"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hak Akses User")])])])]}}],null,false,3533789478)}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-dark font-weight-bolder"},[_vm._v("Pengaturan")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])}]

export { render, staticRenderFns }