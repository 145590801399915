<template>
  <ul class="menu-nav" >

    <li class="menu-section">
      <h4 class="menu-text text-dark font-weight-bolder">Kegiatan</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!--begin::Content-->
    <!-- <div
      class="offcanvas-content pr-5 mr-n5 scroll ps"
      style="height: 804px; overflow: hidden"
    > -->
      <div class="row gutter-b">
        <!--begin::Item-->
        <div class="col-6 px-2 text-center">
          <router-link :to="'/weighing-notes'" active v-if="weighing_toddler">
            <a
              href="#"
              class="
                card-custom card-stretch gutter-b
                card-mapel
                btn btn-block btn-light btn-hover-primary
                text-dark-50 text-center px-0
                border-none
              "
              style="background-color: white; border: none;"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="/media/svg/icons/General/User.svg" /> -->
                <i class="fas fa-balance-scale fa-2x ml-auto text-primary mt-3"></i>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold mt-2 text-dark font-size-lg">
                Timbang
              </span>
              <span class="d-block font-weight-bold mb-auto text-dark font-size-lg">
                Bayi & Balita
              </span>
            </a>
          </router-link>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="col-6 px-2">
          <router-link :to="'/weighing-mothers'" v-if="weighing_mother">
            <a
              href="#"
              class="
                card-custom card-stretch gutter-b
                card-mapel
                btn btn-block btn-light btn-hover-primary
                text-dark-50 text-center px-0
              "
              style="background-color: white; border: none;"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="/media/svg/icons/General/User.svg" /> -->
                <i class="fas fa-balance-scale fa-2x text-primary mt-3"></i>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold mt-2 text-dark font-size-lg">
                Timbang
              </span>
              <span class="d-block font-weight-bold mb-auto text-dark font-size-lg">
                Ibu Hamil
              </span>
            </a>
          </router-link>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="col-6 px-2" v-if="weighing_report">
          <router-link :to="'/weighing-notes/report'">
            <a
              href="#"
              class="
                card-custom card-stretch gutter-b
                card-mapel
                btn btn-block btn-light btn-hover-primary
                text-dark-50 text-center px-0
              "
              style="background-color: white; border: none;"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="/media/svg/icons/General/User.svg" /> -->
                <i class="fas fa-file fa-2x text-primary"></i>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold mb-auto mt-2 text-dark font-size-lg">
                Laporan Penimbangan
              </span>
            </a>
          </router-link>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="col-6 px-2" v-if="report_visitor">
          <router-link :to="'/report'">
            <a
              href="#"
              class="
                card-custom card-stretch gutter-b
                card-mapel
                btn btn-block btn-light btn-hover-primary
                text-dark-50 text-center px-0
              "
              style="background-color: white; border: none;"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="/media/svg/icons/General/User.svg" /> -->
                <i class="fas fa-file fa-2x text-primary"></i>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold mb-auto mt-2 text-dark font-size-lg">
                Laporan Pengunjung
              </span>
            </a>
          </router-link>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="col-6 px-2" v-if="weighing_report_all">
          <router-link :to="'/weighing-notes/report-all'">
            <a
              href="#"
              class="
                card-custom card-stretch gutter-b
                card-mapel
                btn btn-block btn-light btn-hover-primary
                text-dark-50 text-center px-0
              "
              style="background-color: white; border: none;"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="/media/svg/icons/General/User.svg" /> -->
                <i class="fas fa-file fa-2x text-primary"></i>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold mb-auto mt-2 text-dark font-size-lg">
                Laporan Penimbangan Semua Posyandu
              </span>
            </a>
          </router-link>
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="col-6 px-2" v-if="report_visitor_all">
          <router-link :to="'/report/visitor-all'">
            <a
              href="#"
              class="
                card-custom card-stretch gutter-b
                card-mapel
                btn btn-block btn-light btn-hover-primary
                text-dark-50 text-center px-0
              "
              style="background-color: white; border: none;"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="/media/svg/icons/General/User.svg" /> -->
                <i class="fas fa-file fa-2x text-primary"></i>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold mb-auto mt-2 text-dark font-size-lg">
                Laporan Pengunjung Semua Posyandu
              </span>
            </a>
          </router-link>
        </div>
        <!--end::Item-->
      </div>
      <!-- <div class="row justify-content-center image-aside-position">
        <img src="/img/default/aside-transparent.png" alt="" height="200">
      </div> -->
    <!-- </div> -->
    <!--end::Content-->
  </ul>
</template>

<script>
export default {
  data(){
    return{
      weighing_toddler: false,
      weighing_mother: false,
      weighing_report: false,
      weighing_report_all: false,
      report_visitor: false,
      report_visitor_all: false,
    }
  },
  methods: {
    hasActiveChildren(match) {
      //("match", match)
      return this.$route["path"].indexOf(match) !== -1;
    },
    handleToggle(){
      this.$root.$emit('closeToggleAside')
      //("jalan di sini ============================================================================")
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001" || access_right[a] == "1002") {
          this.weighing_toddler = true
        }
        if (access_right[a] == "1003" || access_right[a] == "1004") {
          this.weighing_mother = true
        }
        if (access_right[a] == "1005") {
          this.weighing_report = true
        }
        if (access_right[a] == "1006") {
          this.report_visitor = true
        }
        if (access_right[a] == "1007") {
          this.weighing_report_all = true
        }
        if (access_right[a] == "1008") {
          this.report_visitor_all = true
        }
      }
    },
  },
  mounted(){
    this.checkAccess()
  }
};
</script>

<style scoped>
.image-aside-position {
  margin-top: 5rem !important;
}

@media (max-width: 767.98px) { 
  .image-aside-position {
    margin-top: 7rem !important;
  }
}
</style>