<template>
<div>
  <ul class="menu-nav">
    <li class="menu-section">
      <h4 class="menu-text text-dark font-weight-bolder">Pengaturan</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      to="/masters/roles"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Jabatan</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/masters/admin"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="admin"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Admin</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/masters/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/user-managements/role-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="roleAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-cog"></i>
          <span class="menu-text">Hak Akses Peran</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/user-managements/user-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Hak Akses User</span>
        </a>
      </li>
    </router-link>
  </ul>
  <!-- <div class="row justify-content-center" style="margin-top: 80px">
        <img src="/img/default/aside.jpeg" alt="" height="200">
  </div> -->
</div>
</template>

<script>
export default {
  name: "Master",
  data(){
    return{
      // access
      settingTitle: false,
      settingView: false,
      role: false,
      admin: false,
      user: false,
      roleAccess: false,
      userAccess: false,
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6001" || access_right[a] == "6002") {
          this.settingTitle = true
          this.roleAccess = true
        }
        if (access_right[a] == "6003" || access_right[a] == "9004") {
          this.settingTitle = true
          this.userAccess = true
        }
        if (access_right[a] == "6005" || access_right[a] == "6006") {
          this.settingTitle = true
          this.role = true
        }
        if (access_right[a] == "6007" || access_right[a] == "6008") {
          this.settingTitle = true
          this.admin = true
        }
        if (access_right[a] == "6009") {
          this.settingTitle = true
          this.user = true
        }
      }
    },
  },
  mounted(){
    this.checkAccess()
    this.$root.$on("refreshMasterAside", () => {
      //('refreshMasterAside')
      this.checkAccess();
    });
  }
};
</script>
