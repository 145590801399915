<template>
<div>
  <ul class="menu-nav">
    <li class="menu-section" v-if="masterTitle">
      <h4 class="menu-text text-dark font-weight-bolder">Data Pendukung</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/masters/posyandu"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="posyandu"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clinic-medical"></i>
          <span class="menu-text text-dark">Daftar Posyandu</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/vitamin-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="vitamin_types"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-pills"></i>
          <span class="menu-text text-dark">Jenis Vitamin</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/immunization-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="immunization_types"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-syringe"></i>
          <span class="menu-text text-dark">Jenis Imunisasi</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/blood-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="blood_types"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-tint"></i>
          <span class="menu-text text-dark">Golongan Darah</span>
        </a>
      </li>
    </router-link>

  </ul>

  <!-- <div class="row justify-content-center image-aside-position">
        <img src="/img/default/aside.jpeg" alt="" height="200">
  </div> -->
</div>
</template>

<script>
export default {
  name: "Master",
  data(){
    return{
      // access
      masterTitle: false,
      posyandu: false,
      vitamin_types: false,
      immunization_types: false,
      blood_types: false,
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "5001" || access_right[a] == "5002") {
          this.posyandu = true
          this.masterTitle = true
        }
        if (access_right[a] == "5003" || access_right[a] == "5004") {
          this.vitamin_types = true
          this.masterTitle = true
        }
        if (access_right[a] == "5005" || access_right[a] == "5006") {
          this.immunization_types = true
          this.masterTitle = true
        }
        if (access_right[a] == "5007") {
          this.blood_types = true
          this.masterTitle = true
        }
      }
    },
  },
  mounted(){
    this.checkAccess()
    this.$root.$on("refreshMasterAside", () => {
      //('refreshMasterAside')
      this.checkAccess();
    });
  }
};
</script>

<style scoped>
.image-aside-position {
  margin-top: 8rem !important;
}

@media (max-width: 767.98px) { 
  .image-aside-position {
    margin-top: 9rem !important;
  }
}
</style>