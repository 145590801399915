<template>
  <div
    class="d-flex flex-column flex-root"
    v-if="isAuthenticated"
    @click="handleTimeLog"
  >
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop v-if="$route.name != 'message-box-message'"></KTScrollTop>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import ApiService from "@/core/services/api.service.js";
import {
  getUser,
  getToken,
  saveToken,
  getRefreshToken,
} from "@/core/services/jwt.service.js";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    KTScrollTop,
    Loader,
  },
  data() {
    return {
      timeLog: 60,
      userData: getUser(),
      isCount: true,
      scrollTop: true,
      // refresh-token
      refreshToken: {
        grant_type: "refresh_token",
        refresh_token: getRefreshToken(),
        client_id: "2",
        // Host
        // client_secret: "FJix0vai8ZELvHHhb7ZqrHXlcZUkfofXnHq7TFJf",
        // Local
        client_secret: "SLp286fs76Dg6rSqkp4t2jRYH0LCd6DdOtwr2vk5",
        scope: "*",
      },
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    } else {
      // this.setRefreshToken()
      // this.$store.dispatch(VERIFY_AUTH)
      ApiService.put(`api/users/${getUser().id}/set-user-on`)
        .then((response) => {
          //("success set on");
          // this.timeLog = 60
          // this.countTimeLog()
          // this.$root.$emit('refreshUserOnline')
          //("success set on");
        })
        .catch((error) => {
          //("error set on");
          // this.$root.$emit('refreshUserOnline')
        });

        // this.verifyApi()
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);

    this.countTimeLog();

    window.addEventListener("beforeunload", this.confirmSave);
    this.isCount = true;

    this.$root.$on("stopTimeLog", () => {
      this.isCount = false;
    });

    this.$root.$on("openChatPage", () => {
      this.scrollTop = false;
    });
  },
  methods: {
    verifyApi() {
      ApiService.get(`oauth/tokens`)
        .then((response) => {
          //("oauth/token", response);
        })
        .catch((error) => {
          //("error oauth/token", error.response);
        });
    },
    confirmSave(event) {
      // if (this.onChangeMode) {
      // event.returnValue = 'You have some unsaved changes'
      // //('event', event)
      ApiService.put(`api/users/${this.userData.id}/set-user-off`)
        .then((response) => {
          //("success set on");
          this.timeLog = 60;
          this.countTimeLog();
          this.$root.$emit("refreshUserOnline");
        })
        .catch((error) => {
          //("error set on");
          this.$root.$emit("refreshUserOnline");
        });
      //("event", event);
      return "You have some unsaved changes";
      // }
    },
    handleTimeLog() {
      if (this.timeLog != 0) {
        this.timeLog = 60;
      } else if (this.timeLog < 1) {
        this.isCount = true;
        ApiService.put(`api/users/${getUser().id}/set-user-on`)
          .then((response) => {
            //("success set on");
            this.timeLog = 60;
            this.countTimeLog();
            this.$root.$emit("refreshUserOnline");
          })
          .catch((error) => {
            //("error set on");
            this.$root.$emit("refreshUserOnline");
          });
      }
    },
    countTimeLog() {
      if (this.timeLog != 1) {
        setTimeout(() => {
          if (this.isCount == true) {
            this.countTimeLog();
          }
          this.timeLog = this.timeLog - 1;
          // //(this.timeLog)
        }, 1000);
      } else {
        //("set off");
        //("timelog", this.timeLog);
        ApiService.put(`api/users/${this.userData.id}/set-user-off`)
          .then((response) => {
            this.isCount = false;
            //("success set off");
            this.$root.$emit("refreshUserOnline");
          })
          .catch((error) => {
            //("error set off");
            this.$root.$emit("refreshUserOnline");
          });
      }
    },
    setRefreshToken() {
      ApiService.post("oauth/token", this.refreshToken)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          ApiService.setHeader();
          //("Data Return Refresh Token", data);
          JwtService.saveRefreshToken(data.refresh_token);
        })
        .catch(({ error }) => {
          //("Gagal Refresh Token", error);
          // context.commit(SET_ERROR, data);
        });
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>

