var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._m(0),_c('div',{staticClass:"row gutter-b"},[(_vm.material_stock)?_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":'/material-stock'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"\n            card-custom card-stretch\n            gutter-b\n            card-mapel\n            btn btn-block btn-light btn-hover-primary\n            text-dark-50 text-center\n            px-0\n          ",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],staticStyle:{"background-color":"white","border":"none"},attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary mt-auto m-0"},[_c('i',{staticClass:"fas fa-boxes fa-2x text-primary"})]),_c('span',{staticClass:"\n              d-block\n              text-dark\n              font-weight-bold font-size-h6\n              mb-auto\n              mt-2\n            "},[_vm._v(" Persediaan Bahan ")])])]}}],null,false,1372693481)})],1):_vm._e(),(_vm.stock_history)?_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":'/stock-history'}},[_c('a',{staticClass:"\n            card-custom card-stretch\n            gutter-b\n            card-mapel\n            btn btn-block btn-light btn-hover-primary\n            text-dark-50 text-center\n            px-0\n          ",staticStyle:{"background-color":"white","border":"none"},attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary mt-auto m-0"},[_c('i',{staticClass:"fas fa-clipboard-list fa-2x text-primary"})]),_c('span',{staticClass:"d-block text-dark font-weight-bold font-size-h6 mb-auto"},[_vm._v(" Riwayat Stok ")])])])],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-dark font-weight-bolder"},[_vm._v("Bahan & Material")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])}]

export { render, staticRenderFns }