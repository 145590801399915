<template>
  <div
    class="subheader py-3 py-lg-8 subheader-transparent"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap
      "
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-baseline mr-5">
        <!--begin::Page Title-->
        <h2 class="subheader-title text-dark font-weight-bold my-2 mr-3">
          {{ title }}
        </h2>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="">
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class=""
                style="color: #043404"
                :key="`${i}-${breadcrumb.id}`"
                v-if="!breadcrumb.route && breadcrumb.purpose !== 'message'"
              >
                {{ breadcrumb.title }}
              </span>
              <span
                class="text-muted"
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.purpose === 'message'"
              >
                {{ breadcrumb.role }}
              </span>
            </li><b-icon icon="dot" v-if="i != breadcrumbs.length - 1"></b-icon>
          </template>
        </ul>
        <!-- begin::Announcement -->
        <!-- <div class="announcement-position">
          <marquee class="text-danger marque-loop" :loop="loopTotal" v-if="startContent">
            <span v-html="announcementContent"></span>
          </marquee>
        </div> -->
        <!-- <div class="announcement-position">
          <marquee class="text-danger">
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum in dolorem, minus consequatur cum odio ab maiores modi commodi natus reprehenderit deserunt aspernatur omnis voluptas pariatur accusamus quidem asperiores perferendis?</span>
          </marquee>
        </div> -->
        <!--end::Announcement-->
        <!--end::Breadcrumb-->
      </div>

      <!-- <div class="">
        <span class="font-weight-bolder">{{currentUser.posyandu_name}}</span>
      </div> -->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center" v-if="$isMobile()">
      </div>
      <div class="d-flex align-items-center" v-else>
        <!--begin::Button-->
        <!-- <a
          href="#"
          class="btn btn-fh btn-white font-weight-bold px-2 px-lg-5 mr-2"
        > -->
          <span class="text-right mr-1">
            <span class="text-primary mr-2 text-danger font-weight-bold">{{currentUser.posyandu_name}}</span>
            <span class="text-dark-75 font-weight-bold">{{userData.name}}</span>
          </span>

          <span class="symbol symbol-35 symbol-light-success">
        <span
          class="symbol-label font-size-h5 font-weight-bold"
        >
          {{ currentUser.name.charAt(0).toUpperCase() }}
        </span>
      </span>
        <!-- </a> -->
        <!--end::Button-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    return {
      mainProps: { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' },
      announcements: [],
      loopTotal: 3,
      announcementContent: '',
      startContent: false,
      userData: {},
      currentUser: getUser()
    };
  },
  methods: {
    onStart(evt){
      //("onstart", evt)
    },
    onFinish(evt){
      //("onFinish", evt)
    },
    async getAnnouncementActive() {
      let response = await module.paginate("api/announcements/get-shows");

      this.announcements = response.data;
      for(let a = 0; a < this.announcements.length; a++){
        this.announcementContent += `${this.announcements[a].content} <img src="/img/login/SMKN4_bersih.png" alt="Logo" class="max-h-15px" />`
        if(a + 1 == this.announcements.length){
          this.startContent = true

          setTimeout(() => {
            let el = this.$el.getElementsByClassName("marque-loop")[0].loop = 3
          }, 1000);
        }
      }
    },
    async get() {
      this.userData = await module.get('api/users/' + getUser().id)
    },
  },
  mounted() {
    // this.getAnnouncementActive();
    this.get()

    // this.$root.$on("refreshAnnouncementActive", () => {
    //   this.loopTotal = this.loopTotal + 3
    //   this.getAnnouncementActive();
    // });

    this.$root.$on("refreshCurrentUser", () => {
      this.get()
    });

    // this.$nextTick(() => {
    //   let newEl = this.$el.getElementsByClassName("marque-loop")
    //   //("new element", newEl)
    // });
    // //("element", el)
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
.announcement-position{
  /* style="position: absolute; bottom: 0px; width: 90%" */
  position: absolute;
  bottom: 0px;
  width: 90%;
}

/* mobile */
@media (max-width: 767.98px) { 
.announcement-position{
  /* style="position: absolute; bottom: 0px; width: 90%" */
  position: absolute;
  top: 10%;
  width: 90%;
}
}
</style>