<template>
  <ul class="menu-nav">
    <li class="menu-section">
      <h4 class="menu-text text-dark font-weight-bolder">Bahan & Material</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!--begin::Content-->
    <!-- <div
      class="offcanvas-content pr-5 mr-n5 scroll ps"
      style="height: 804px; overflow: hidden"
    > -->
    <div class="row gutter-b">
      <!--begin::Item-->
      <div class="col-6" v-if="material_stock">
        <router-link
          :to="'/material-stock'"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="
              card-custom card-stretch
              gutter-b
              card-mapel
              btn btn-block btn-light btn-hover-primary
              text-dark-50 text-center
              px-0
            "
            style="background-color: white; border: none"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
              <!--begin::Svg Icon-->
              <i class="fas fa-boxes fa-2x text-primary"></i>
              <!--end::Svg Icon-->
            </span>
            <span
              class="
                d-block
                text-dark
                font-weight-bold font-size-h6
                mb-auto
                mt-2
              "
            >
              Persediaan Bahan
            </span>
          </a>
        </router-link>
      </div>
      <!--end::Item-->
      <!--begin::Item-->
      <div class="col-6" v-if="stock_history">
        <router-link :to="'/stock-history'">
          <a
            href="#"
            class="
              card-custom card-stretch
              gutter-b
              card-mapel
              btn btn-block btn-light btn-hover-primary
              text-dark-50 text-center
              px-0
            "
            style="background-color: white; border: none"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary mt-auto m-0">
              <!--begin::Svg Icon-->
              <i class="fas fa-clipboard-list fa-2x text-primary"></i>
              <!--end::Svg Icon-->
            </span>
            <span
              class="d-block text-dark font-weight-bold font-size-h6 mb-auto"
            >
              Riwayat Stok
            </span>
          </a>
        </router-link>
      </div>
      <!--end::Item-->
      
    </div>
    <!-- <div class="row justify-content-center image-aside-position">
      <img src="/img/default/aside-transparent.png" alt="" height="200" />
    </div> -->
    <!-- </div> -->
    <!--end::Content-->
  </ul>
</template>

<script>
export default {
  data() {
    return {
      material_stock: false,
      stock_history: false,
    };
  },
  methods: {
    hasActiveChildren(match) {
      //("match", match)
      return this.$route["path"].indexOf(match) !== -1;
    },
    handleToggle() {
      this.$root.$emit("closeToggleAside");
      //("jalan di sini ============================================================================")
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3001" || access_right[a] == "3002") {
          this.material_stock = true;
        }
        if (access_right[a] == "3003" || access_right[a] == "3004") {
          this.stock_history = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>
.image-aside-position {
  margin-top: 14rem !important;
}

@media (max-width: 767.98px) { 
  .image-aside-position {
    margin-top: 17rem !important;
  }
}
</style>